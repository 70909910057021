<template>
  <div class="flex">
    <div class="w-2/5">
      <label class="w-full block mb-6">
        <span class="label">Selecione o correlato</span>
        <select class="select" v-model="editingCorrelate">
          <option value="">Selecione</option>
          <option value="c3">1C</option>
          <option value="c2">2C</option>
          <option value="c1">3C</option>
        </select>
      </label>

      <label class="block mb-6">
        <ul
          v-if="editingCorrelate"
          class="rounded border border-gray-200 shadow"
        >
          <li
            v-for="(correlate, key) in this[editingCorrelate]"
            :key="key"
            class="border-b border-gray-200 py-2 px-4 text-gray-800 font-bold flex justify-between items-center"
          >
            <div>{{ correlate.pt.title }}</div>
            <div class="button bg-blue-600" @click.prevent="editing = key">
              Editar
            </div>
          </li>
        </ul>
      </label>
    </div>

    <div class="w-1/2 ml-4" v-if="editing">
      <label class="w-full block mb-6 pl-4">
        <span class="label">Selecione a língua</span>
        <select class="select" v-model="lang">
          <option
            v-for="lang in languages"
            :key="lang.abbreviation"
            :value="lang.abbreviation"
            >{{ lang.title }}</option
          >
        </select>
      </label>

      <form v-if="lang" @submit.prevent="saveCorrelates()" class="pl-4">
        <label class="block mb-4">
          <span class="label">Título {{ lang.title }}</span>
          <input type="text" class="input" v-model="setTitle" />
        </label>

        <label class="block mb-4">
          <span class="label">Descrição {{ lang.title }}</span>
          <textarea class="input" v-model="setDescription"></textarea>
        </label>

        <div
          v-if="message"
          class="bg-green-300 border rounded shadow mb-4 border-green-700 text-green-800 px-3 py-2 font-bold"
        >
          {{ message }}
        </div>
        <div
          v-if="errorMessage"
          class="bg-red-300 border rounded shadow mb-4 border-red-700 text-red-800 px-3 py-2 font-bold"
        >
          {{ errorMessage }}
        </div>

        <AppButton :loading="loading">Salvar</AppButton>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppButton from "@/components/AppButton";

export default {
  name: "Correlates",
  components: {
    AppButton
  },
  filters: {},
  props: {},
  data: function() {
    return {
      loading: false,

      editingCorrelate: null,
      editing: null,

      c1: null,
      c2: null,
      c3: null,

      lang: null,

      message: null,
      errorMessage: null
    };
  },
  computed: {
    ...mapState({
      statec1: state => state.Settings.c1,
      statec2: state => state.Settings.c2,
      statec3: state => state.Settings.c3,
      languages: state => state.Settings.languages
    }),

    setTitle: {
      get() {
        return this[this.editingCorrelate] && [this.editing] &&
          this[this.editingCorrelate][this.editing]
          ? this[this.editingCorrelate][this.editing][this.lang].title
          : "";
      },
      set(value) {
        this[this.editingCorrelate][this.editing][this.lang].title = value;
      }
    },

    setDescription: {
      get() {
        return this[this.editingCorrelate] && [this.editing] &&
          this[this.editingCorrelate][this.editing]
          ? this[this.editingCorrelate][this.editing][this.lang].description
          : "";
      },
      set(value) {
        this[this.editingCorrelate][this.editing][
          this.lang
        ].description = value;
      }
    }
  },
  watch: {
    editing() {
      this.loading = false;
      this.message = null;
      this.errorMessage = null;
    },
    statec1: {
      handler() {
        this.parseLanguage(this.statec1, "c1");
        this.parseLanguage(this.statec2, "c2");
        this.parseLanguage(this.statec3, "c3");
      },
      deep: true
    },
    statec2: {
      handler() {
        this.parseLanguage(this.statec1, "c1");
        this.parseLanguage(this.statec2, "c2");
        this.parseLanguage(this.statec3, "c3");
      },
      deep: true
    },
    statec3: {
      handler() {
        this.parseLanguage(this.statec1, "c1");
        this.parseLanguage(this.statec2, "c2");
        this.parseLanguage(this.statec3, "c3");
      },
      deep: true
    }
  },
  async created() {
    await this.$store.dispatch("Settings/list");
    this.parseLanguage(this.statec1, "c1");
    this.parseLanguage(this.statec2, "c2");
    this.parseLanguage(this.statec3, "c3");
  },
  async mounted() {},
  methods: {
    async saveCorrelates() {
      this.loading = true;
      this.message = null;
      this.errorMessage = null;

      const response = await this.$store.dispatch("Settings/update", [
        `aspects_${this.editingCorrelate}`,
        {
          [this.editingCorrelate]: { ...this[`${this.editingCorrelate}`] }
        }
      ]);

      if (response) {
        this.message = "Salvo com sucesso!";
      } else {
        this.errorMessage = "Ops, deu pau, fala com o Edu!";
      }

      this.loading = false;
    },

    parseLanguage(list, target) {
      const localList = { ...list };

      this.languages.forEach(item => {
        for (let key in localList) {
          if (!localList[key][item.abbreviation]) {
            localList[key][item.abbreviation] = {
              title: "",
              description: ""
            };
          }
        }
      });

      this[target] = localList;
    }
  }
};
</script>

<style scoped lang="scss"></style>
